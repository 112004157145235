import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {MarkdownRemark} from '../models/markdown-remark';
import {TitleSection} from '../components/integrations/title-section';
import styled from 'styled-components';
import {BackgroundFigure} from '../components/shared/background-figure';
import ReactMarkdown from 'react-markdown';
import {device} from '../models/devices';

interface IntegrationPageData extends BasePageData {
  integrationsData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    description: string;
    subSections: Array<{
      integrations: {
        title: string;
        integrationNames: string[];
      }
    }>;
  }>
}

const Grid = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    padding: 0 24px;
    grid-gap: 24px;
  }
`;

const Integration = styled.a`
  display: flex;
  align-items: center;
  border: solid 1px #e3e3e3;
  padding: 16px;
  text-decoration: none;

  &[href] {
    cursor: pointer;
  }

  &:hover {
    background: #EFEFEF;
  }
`;

const SectionContainer = styled.section`
  margin-bottom: 119px;
  
  @media screen and ${device.tablet} {
    margin-bottom: 80px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 48px;
  font-weight: 500;
  color: #4f40ab;
  text-align: center;
  margin-top: 118px;
  margin-bottom: 52px;
  
  @media screen and ${device.tablet} {
    font-size: 32px;
    margin-bottom: 34px;
    text-align: left;
    padding: 0 24px;
    margin-top: 68px;
  }
`;

const IntegrationPage = ({data}: {data: IntegrationPageData}): JSX.Element => {

  const integrationLogos = data.integrations.edges.map(edge => ({
    url: edge.node.frontmatter.logo,
    alt: edge.node.frontmatter.title,
    position: edge.node.frontmatter.position,
    color: edge.node.frontmatter.backgroundColor
  }));

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.integrationsData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
      />
      <TitleSection
        title={data.integrationsData.edges[0].node.frontmatter.title}
        text={data.integrationsData.edges[0].node.frontmatter.description}
        logos={integrationLogos}
        centerLogo={data.logos.edges[0].node.frontmatter.logoShort}
      />
      {
        data.integrationsData.edges[0].node.frontmatter.subSections
          .map(({integrations}, index) => (
            <React.Fragment key={index}>
              {
                <SectionContainer key={index}>
                  <SectionTitle>{integrations.title}</SectionTitle>
                  <Grid>
                    {
                      integrations.integrationNames.map((integrationName, j) => {
                        const integrationItem = data.integrations.edges
                          .find(company => company.node.frontmatter.title === integrationName)
                          ?.node.frontmatter;
                        const hrefAttribute = integrationItem?.url
                          ? { href: integrationItem.url }
                          : {};
                        return (
                          <Integration key={j} {...hrefAttribute} target="_blank" rel="noreferrer">
                            <img
                              width={114}
                              style={{
                                marginRight: 24,
                                flexShrink: 0,
                                flexGrow: 0,
                                padding: 10,
                                boxSizing: 'border-box',
                                backgroundColor: integrationItem?.backgroundColor
                              }}
                              src={integrationItem?.logo}
                              alt=""
                            />
                            <div>
                              <div style={{fontSize: 18, fontWeight: 500, marginBottom: 8}}>
                                { integrationItem && <ReactMarkdown>{integrationItem?.title}</ReactMarkdown> }
                              </div>
                              <span style={{fontSize: 16, color: '#54595a'}}>
                                <ReactMarkdown>{integrationItem?.description ?? ''}</ReactMarkdown>
                              </span>
                            </div>
                          </Integration>
                        );
                      })
                    }
                  </Grid>
                </SectionContainer>
              }
            </React.Fragment>
          ))
      }
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 23, height: 46}}
          position={{right: 0, top: 1120}}
          style={{
            borderRadius: 23,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{left: 0, top: 1160}}
        />
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default IntegrationPage;

export const pageQuery = graphql`
  {
    ...BaseData
    integrationsData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/integrations.md/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            description
            subSections {
              integrations {
                title
                integrationNames
              }
            }
          }
        }
      }
    }
  }
`;
