import React, {CSSProperties} from 'react';
import Background from '../../images/product-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {IntegrationLogo} from '../../models/integration-logo';
import {Squircle} from '../../helpers/squircle';
import {device} from '../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat bottom center;
  background-size: cover;
  padding-bottom: 152px;
  
  @media screen and ${device.tablet} {
    padding-bottom: 76px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 190px;

  @media screen and ${device.tablet} {
    padding: 115px 24px 0;
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin: 0 auto 32px;
  text-align: center;
  max-width: 970px;
  letter-spacing: -0.2px;
  
  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 24px;
    max-width: initial;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #cfe7fc;
  grid-area: text;
  letter-spacing: -0.1px;
  margin-bottom: 24px;
  text-align: center;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  }
`;

const Item = styled.div<{
  angle: number;
  desktopWidth: number;
  desktopTop: number;
  desktopLeft: number;
  mobileWidth: number;
  mobileTop: number;
  mobileLeft: number;
  color: string;
}>`
  position: absolute;
  top: ${props => props.desktopTop}px;
  left: ${props => props.desktopLeft}px;
  filter: drop-shadow(4px 10px 12px rgba(0, 0, 0, 0.15));

  &:before {
    width: ${props => 1.6*props.desktopWidth}px;
    height: ${props => 1.6*props.desktopWidth}px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${props => Squircle(props.color)});
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%) rotate(${props => props.angle}deg);
    background-size: contain;
  }
  
  img {
    width: ${props => props.desktopWidth}px;
  }

  @media screen and (max-width: 1290px) {
    top: ${props => props.mobileTop}px;
    left: ${props => props.mobileLeft}px;
    &.desktop-only {
      display: none;
    }
    
    &:before {
      width: ${props => 1.6*props.mobileWidth}px;
      height: ${props => 1.6*props.mobileWidth}px;
    }

    img {
      width: ${props => props.mobileWidth}px;
    }
  }
`;

const logoStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};

const logoConfig: Array<{
  [screen in 'desktop' | 'mobile']: {
    position: {
      top: number;
      left: number;
    };
    size: {
      width: number;
    }
  };
} & {
  mappedPosition: number;
}> = [
  // R1C1
  {
    desktop: {
      position: {
        top: 101,
        left: 67
      },
      size: {
        width: 59,
      },
    },
    mobile: {
      position: {
        top: 40,
        left: 116
      },
      size: {
        width: 48,
      }
    },
    mappedPosition: 7
  },
  // R1C2
  {
    desktop: {
      position: {
        top: 104,
        left: 262
      },
      size: {
        width: 30,
      },
    },
    mobile: {
      position: {
        top: 356,
        left: 122
      },
      size: {
        width: 38,
      }
    },
    mappedPosition: 0
  },
  // R1C3
  {
    desktop: {
      position: {
        top: 82,
        left: 437
      },
      size: {
        width: 87 ,
      },
    },
    mobile: {
      position: {
        top: 40,
        left: 223
      },
      size: {
        width: 62,
      }
    },
    mappedPosition: 2
  },
  // R1C4
  {
    desktop: {
      position: {
        top: 107,
        left: 767
      },
      size: {
        width: 71,
      },
    },
    mobile: {
      position: {
        top: 187,
        left: 20
      },
      size: {
        width: 38,
      }
    },
    mappedPosition: 3
  },
  // R1C5
  {
    desktop: {
      position: {
        top: 52,
        left: 1077
      },
      size: {
        width: 72,
      },
    },
    mobile: {
      position: {
        top: 266,
        left: 232
      },
      size: {
        width: 50,
      }
    },
    mappedPosition: 9
  },
  // R2C1
  {
    desktop: {
      position: {
        top: 247,
        left: 147
      },
      size: {
        width: 72,
      },
    },
    mobile: {
      position: {
        top: 296,
        left: 40
      },
      size: {
        width: 50,
      }
    },
    mappedPosition: 1
  },
  // R2C2
  {
    desktop: {
      position: {
        top: 235,
        left: 372
      },
      size: {
        width: 67,
      },
    },
    mobile: {
      position: {
        top: 356,
        left: 210
      },
      size: {
        width: 50,
      }
    },
    mappedPosition: 5
  },
  // R2C3
  {
    desktop: {
      position: {
        top: 272,
        left: 753
      },
      size: {
        width: 60,
      },
    },
    mobile: {
      position: {
        top: 70,
        left: 23
      },
      size: {
        width: 46,
      }
    },
    mappedPosition: 6
  },
  // R2C4
  {
    desktop: {
      position: {
        top: 324,
        left: 933
      },
      size: {
        width: 43,
      },
    },
    mobile: {
      position: {
        top: 115,
        left: 155
      },
      size: {
        width: 30,
      }
    },
    mappedPosition: 8
  },
  // R2C5
  {
    desktop: {
      position: {
        top: 200,
        left: 959
      },
      size: {
        width: 37,
      },
    },
    mobile: {
      position: {
        top: 168,
        left: 223,
      },
      size: {
        width: 28,
      }
    },
    mappedPosition: 4
  },
  // R2C6
  {
    desktop: {
      position: {
        top: 277,
        left: 1143
      },
      size: {
        width: 70,
      },
    },
    mobile: {
      position: {
        top: 210,
        left: 136
      },
      size: {
        width: 66,
      }
    },
    mappedPosition: 10
  },
];

const centerCoords = {
  position: {
    top: 192,
    left: 578
  },
  size: {
    width: 79,
  },
};

const ItemContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  height: 330px;
  

  @media screen and (max-width: 1290px) {
    max-width: 272px;
    height: 400px;
  }
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  logos: IntegrationLogo[];
  centerLogo: string;
}): JSX.Element => {

  const mappedConfig: Record<number, {
    [screen in 'desktop' | 'mobile']: {
      position: {
        top: number;
        left: number;
      };
      size: {
        width: number;
      }
    };
  } & {
    mappedPosition: number;
  }> = {};

  logoConfig.forEach(config => mappedConfig[config.mappedPosition] = config);

  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <ItemContainer>
          {props.logos.map((logo, index) => {
            return (
              <Item
                key={index}
                angle={Math.round(Math.random() * 360)}
                desktopWidth={mappedConfig[logo.position]?.desktop.size.width}
                desktopTop={mappedConfig[logo.position]?.desktop.position.top}
                desktopLeft={mappedConfig[logo.position]?.desktop.position.left}
                mobileTop={mappedConfig[logo.position]?.mobile.position.top}
                mobileLeft={mappedConfig[logo.position]?.mobile.position.left}
                mobileWidth={mappedConfig[logo.position]?.mobile.size.width}
                color={logo.color ?? '#FFF'}
              >
                <img
                  src={logo.url}
                  alt={logo.alt}
                  style={logoStyle}
                />
              </Item>
            );
          })}
          <Item
            className={'desktop-only'}
            angle={Math.round(Math.random()*360)}
            desktopWidth={centerCoords.size.width}
            desktopTop={centerCoords.position.top}
            desktopLeft={centerCoords.position.left}
            mobileWidth={centerCoords.size.width}
            mobileTop={centerCoords.position.top}
            mobileLeft={centerCoords.position.left}
            color={'#FFF'}
          >
            <img
              src={props.centerLogo}
              alt="Limeflight"
              style={{
                ...logoStyle,
                width: centerCoords.size.width,
              }}
            />
          </Item>
        </ItemContainer>
      </Wrapper>
    </Container>
  );
};
