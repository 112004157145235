import { btoa } from './btoa';

export function Squircle(color: string): string {
  const figure = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="89" height="94" viewBox="0 0 89 94" fill="none">
      <defs>
          <path id="b" d="M10.7 11.8499C24.8875 -2.92881 64.9124 -2.92881 79.1 11.8499C93.2875 26.6286 90.8959 70.8125 79.1 83.0999C67.3041 95.3873 24.8875 97.8786 10.7 83.0999C-3.48756 68.3212 -3.48756 26.6286 10.7 11.8499Z" />
      </defs>
      <g fill-rule="evenodd" clip-rule="evenodd" fill="none">
          <use fill="#000" filter="url(#a)" xlink:href="#b"/>
          <use fill="${color}" xlink:href="#b"/>
      </g>
    </svg>
  `;

  const str = btoa(figure);

  return `data:image/svg+xml;base64,${str}`;
}
